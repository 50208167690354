import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { getAdGroup } from "../services/AuthorizationService/AuthorizationService";

import { AuthConsumer } from "../providers/AuthProvider";
import NotAuthorized from "../components/NotAuthorized/notauthorized";
import LoadingSpinner from "../components/Spinner/spinner";

const GuardedRoute = () => {
  const [adGroup, setADGroup] = useState("");

  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      try {
        const result = await getAdGroup();
        setADGroup(result.adGroup);
      } catch (e: any) {
        setADGroup("not authorized");
        console.log("error " + e);
      }
    };

    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  });
  return (
    <AuthConsumer>
            {({ isAuthorized }) => {
                    if (adGroup === '') {
                        return <span><LoadingSpinner /></span>
                    }
                if (isAuthorized(adGroup.trim())) {
                        console.log("adgroup is " + adGroup);
                        return <Outlet />
                    }
                    else {
                        console.log("not authorized");
                        return <NotAuthorized />
                    }
            }
            }
        </AuthConsumer>     
    )
}
export default GuardedRoute;
