import { FilterRulesList } from "../../Model/FilterRulesList";
import { SchemaItem } from "../../Model/SchemaItem";
import { FilterRuleModel } from "../../Model/FilterRuleModel";
import { checkStatus, parseJSON } from "../Common";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

//get filtration rules list
export async function GetFiltrationRules(
  airline: string,
  token: string
): Promise<FilterRulesList[]> {

  const url = `${apiUrl}/FinancialInterfaceData/GetFilterRules?airlineCode=${airline}`;
  return await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then(checkStatus)
    .then(parseJSON)
    .catch((error: any) => {
      console.log("error while calling the api ", error);
      throw new Error("API Call failed: " + error.message + "");
    });
}

//GetSchemaItems
export async function GetSchemaItems(
  airline: string,
  token: string
): Promise<SchemaItem[]> {

  const url = `${apiUrl}/FinancialInterfaceData/GetSchema?systemName=JE&airlineCode=${airline}`;
  return await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then(checkStatus)
    .then(parseJSON)
    .catch((error: any) => {
      console.log("error while calling the api ", error);
      throw new Error("API Call failed: " + error.message + "");
    });
}

//InsertFilterRule
export async function InsertFilterRule(
  airline: string,
  filterRule: FilterRuleModel,
  token: string
): Promise<{ ruleId: number }> {

  const url = `${apiUrl}/FinancialInterfaceData/InsertFilterRule?airlineCode=${airline}`;
  return await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(filterRule),
  })
    .then(checkStatus)
    .then(parseJSON)
    .catch((error: any) => {
      console.log("error while calling the api ", error);
      throw new Error("API Call failed: " + error.message + "");
    });
}

//delete filtration rules list
export async function DeleteFiltrationRules(
  filterRuleId: number,
  airline: string,
  token: string
): Promise<boolean> {

  const url = `${apiUrl}/FinancialInterfaceData/DeleteFilterRule?filterRuleId=${filterRuleId.toString()}&airlineCode=${airline}`;
  return await fetch(url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then(checkStatus)
    .then(parseJSON)
    .catch((error: any) => {
      console.log("error while calling the api ", error);
      throw new Error("API Call failed: " + error.message + "");
    });
}
