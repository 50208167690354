import { FC, useContext, useState } from "react";
import { AuthContext } from "../../providers/AuthProvider";
import { FleetLookupRequest } from "../../Model/FleetLookupRequest";
import { InsertFleetLookup } from "../../services/FleetLookup/FleetLookupDataService";
import "./FleetLookupDataForm.scss";

interface FleetLookupDataFormProps {
    manufacturer: string;
    onInsert: (data: string) => void;
    onCancel: () => void;
    onError: (data:string)=>void;
  }

  export const FleetLookupDataForm: FC<FleetLookupDataFormProps>=({
    manufacturer,
    onInsert,
    onCancel,
    onError
  })=>{
    const { getAccessToken } = useContext(AuthContext);
    const[busy,setBusy]=useState(false);
    const[acSeries,setacSeries]=useState("");
    const[fleetNumber,setfleetNumber]=useState("");
    const[description,setdescription]=useState("");
    
    const cancelFleetLookupForm = () => {
      onCancel();
    };

    const insertfleetlookup = () => {
      setBusy(true);
       const fleetLookup: FleetLookupRequest = {
        acseries: acSeries,
        fleet_number: fleetNumber,
        description: description
      };
        InsertFleetLookup(manufacturer, fleetLookup, getAccessToken())
        .then((response) => {
          console.info(response);

          if (response)
          {
            onInsert("JE Fleet Lookup record was created for " + manufacturer);          
          }
          else
          {
            onError("Error on save JE Fleet Lookup: " + response + ", Message: API returned false.")          
          }
        })
        .catch((error) => {
          onError("Error on save Fleet Lookup: " + error.message);
        })
        .finally(() => {
          setBusy(false);
        });
    };

    return( 
      <div>      
      <div className="FleetLookupFormField">
        <auro-input id="acSeries" 
          required 
          bordered
          disabled={ busy ? "disabled" : undefined } 
          maxLength={100} 
          value={acSeries} 
          onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                setacSeries(e.currentTarget.value)
              }> 
          <span slot="label">AC Series</span>
          { <span className="inputCounter" slot="helptext">{acSeries.length}/100</span> }
        </auro-input>
    </div>
    <div className="FleetLookupFormField">
      <auro-input id="fleetNumber" 
        required
        bordered
        disabled={ busy ? "disabled" : undefined }       
        maxLength={100} 
        value={fleetNumber} onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setfleetNumber(e.currentTarget.value)
                }> 
        <span slot="label">Fleet Number</span>
        { <span className="inputCounter" slot="helptext">{fleetNumber.length}/100</span> }
      </auro-input>
    </div>
    <div className="FleetLookupFormField">
      <auro-input id="description" 
        bordered
        disabled={ busy ? "disabled" : undefined } 
        maxLength={200} 
        value={description} 
        onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                setdescription(e.currentTarget.value)
              }> 
        <span slot="label">Description</span>
        { <span className="inputCounter" slot="helptext">{description.length}/200</span> }
      </auro-input>
    </div>
  
  <div className="FleetLookupFormFooter">
      <auro-button
        disabled={
          busy
            ? "disabled"
            : undefined
        }
        id="btnCancel"
        slot="trigger"
        onClick={() => cancelFleetLookupForm()}
      >
        Cancel
      </auro-button>
      <auro-button
        
        disabled={
          busy || acSeries.trim()==="" || fleetNumber.trim()===""
            ? "disabled"
            : undefined
        }
        secondary
        onClick={() => insertfleetlookup()}
      >
        Save
      </auro-button>
    </div>
  </div>
    );
}
