import { UserManager, WebStorageStateStore, Log } from "oidc-client";
import { getBaseUrl } from "../../utils/utils";

export default class AuthService {
    
    UserManager;
    baseUrl = getBaseUrl();
    IDENTITY_CONFIG = {
      authority: process.env.REACT_APP_OIDC_PROVIDER,
      client_id: process.env.REACT_APP_OIDC_CLIENT_ID,
      redirect_uri: `${this.baseUrl}${process.env.REACT_APP_OIDC_REDIRECT_ROUTE}`,
      response_type: 'code',
      grant_types: 'authorization_code',
      scope: 'openid profile email',
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      automaticSilentRenew: true,
      useRefreshToken: true,
      silent_redirect_uri: process.env.REACT_APP_REACT_APP_SILENT_REDIRECT_URL, //(string): The URL for the page containing the code handling the silent renew.
      accessTokenExpiringNotificationTime: 300 // 5 minutes prior to Expiration
    };
    METADATA_OIDC = {
        issuer: process.env.REACT_APP_OIDC_PROVIDER,
        jwks_uri: process.env.REACT_APP_OIDC_PROVIDER + "/pf/JWKS",
        authorization_endpoint: process.env.REACT_APP_OIDC_PROVIDER + "/as/authorization.oauth2",
        token_endpoint: process.env.REACT_APP_OIDC_PROVIDER + "/as/token.oauth2",
        userinfo_endpoint: process.env.REACT_APP_OIDC_PROVIDER + "/idp/userinfo.openid",
        //end_session_endpoint: process.env.REACT_APP_OIDC_PROVIDER + "/connect/endsession",
        //check_session_iframe: process.env.REACT_APP_OIDC_PROVIDER + "/connect/checksession",
        revocation_endpoint: process.env.REACT_APP_OIDC_PROVIDER + "/as/revoke_token.oauth2",
        introspection_endpoint: process.env.REACT_APP_OIDC_PROVIDER + "/as/introspect.oauth2"
      };
    constructor() {
        this.UserManager = new UserManager({
            ...this.IDENTITY_CONFIG,
            userStore: new WebStorageStateStore({ store: window.sessionStorage }),
            metadata: {
                ...this.METADATA_OIDC
            }
        });
        // Logger
        Log.logger = console;
        Log.level = Log.DEBUG;
        this.UserManager.events.addUserLoaded((user) => {
            if (window.location.href.indexOf("signin-oidc") !== -1) {
                this.navigateToScreen();
            }
        });
        this.UserManager.events.addSilentRenewError((e) => {
            console.log("silent renew error", e.message);
        });

        this.UserManager.events.addAccessTokenExpired(() => {
            console.log("token expired");
            localStorage.clear();
            sessionStorage.clear();
            localStorage.setItem("redirectUri", window.location.pathname);
            this.UserManager.signinRedirect({});
            });

        this.UserManager.events.addAccessTokenExpiring(() => {
            console.log("token expiring");
            this.signinSilent();
        });

    }

    signinRedirectCallback = () => {
        this.UserManager.signinRedirectCallback().then(() => {
            "";
        });
    };


    getUser = async () => {
        const user = await this.UserManager.getUser();
        if (!user) {
            return await this.UserManager.signinRedirectCallback();
        }
        return user;
    };

    parseJwt = (token: string) => {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace("-", "+").replace("_", "/");
        return JSON.parse(window.atob(base64));
    };


    signinRedirect = () => {
        localStorage.clear();
        sessionStorage.clear();
        localStorage.setItem("redirectUri", window.location.pathname);
        this.UserManager.signinRedirect({});
    };


    navigateToScreen = () => {
        window.location.replace("/en/dashboard");
    };
  
    isAuthorized = (adGroup: string) => {
            let str = sessionStorage.getItem(`oidc.user:${process.env.REACT_APP_OIDC_PROVIDER}:${process.env.REACT_APP_OIDC_CLIENT_ID}`);
            if (str === null) {
                return false;
            }
            else {
                const oidcStorage = JSON.parse(str);
                //Check if AD group exists.
                var validADGroups = oidcStorage.profile.groups.find((element: any) => {
                    return element === adGroup;
                });
                if (validADGroups != null && validADGroups.length > 0) {
                   // alert(validADGroups);
                    sessionStorage.setItem('validADGroups', validADGroups);
                   // alert(sessionStorage.getItem("validADGroups"))
                    return true;
                }
                else {
                    return false;
                }
            }
    }

    isAuthenticated = () => {
        let str = sessionStorage.getItem(`oidc.user:${process.env.REACT_APP_OIDC_PROVIDER}:${process.env.REACT_APP_OIDC_CLIENT_ID}`);
        if (str === null)
        {
            return false;            
        }

        else
        {
            const oidcStorage = JSON.parse(str);
            return (!!oidcStorage && !!oidcStorage.access_token);
        }

    };
    
    getAccessToken = () => {
        let str = sessionStorage.getItem(`oidc.user:${process.env.REACT_APP_OIDC_PROVIDER}:${process.env.REACT_APP_OIDC_CLIENT_ID}`);
        
        if (str === null)
        {
            return false;
        }
        else
        {
            const oidcStorage = JSON.parse(str);
    
            return oidcStorage.access_token            
        }

      }
      
    signinSilent = () => {
        this.UserManager.signinSilent()
            .then((user) => {
                console.log("signed in", user);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    signinSilentCallback = () => {
        this.UserManager.signinSilentCallback().then(() => {
            "";
        });
    };

    createSigninRequest = () => {
        return this.UserManager.createSigninRequest();
    };

    logout = () => {
        this.UserManager.signoutRedirect({
            id_token_hint: localStorage.getItem("id_token")
        });
        this.UserManager.clearStaleState();
    };

    signoutRedirectCallback = () => {
        this.UserManager.signoutRedirectCallback().then(() => {
            localStorage.clear();
            if (process.env.REACT_APP_PUBLIC_URL !== undefined)
            {
                window.location.replace(process.env.REACT_APP_PUBLIC_URL);                
            }

        });
        this.UserManager.clearStaleState();
    };
}

