import { useState } from "react";
import { ReactSVG } from "react-svg";

//Component properties interface
interface JETableProps {
    columnsDefinition: JETableColumns[];
    tableData: any[];    
    keyFieldName: string
    onDeleteClick: (id: number) => void;
    onSortChange: (event: SortRequestModel) => void;         
}

export interface JETableColumns {
    key: string,
    name: string,
    sortable: boolean,
    dataFormat: 'date' | 'string'

}

export interface SortRequestModel {
    fieldName:  string,
    direction: 'asc' | 'desc'
}

export function SortItems(a:any, b:any, event: SortRequestModel): number {
    if(event.fieldName){
      const columnA = a[event.fieldName];
      const columnB = b[event.fieldName];
      if(columnA < columnB) {
        return event.direction === 'asc' ? -1: 1;
      }
      if (columnA > columnB) {
        return event.direction === 'asc' ? 1: -1;
      }
    }
    return 0;
  }

export const JETable = ({ keyFieldName, columnsDefinition, tableData, onDeleteClick, onSortChange } : JETableProps) => {
    //States
    const [sortColumn, setSortColumn] = useState('');
    const [sortDirection, setSortDirection] = useState('asc');
    
    //Event handlers
    const handleSort = (column: string) => {
        let srtDirection : 'asc' | 'desc' = 'asc';
        if(sortColumn === column) {
            srtDirection = sortDirection === 'asc' ? 'desc' : 'asc';
        } 
        setSortDirection(srtDirection);
        setSortColumn(column);
        onSortChange({ fieldName: column, direction: srtDirection })
    };

    
    return (<table className="auro_table">
        <thead>
            <tr key="header">
            {
                columnsDefinition.map((column) => (
                    <td key={column.key} className="TableHeader" onClick={() => column.sortable ? handleSort(column.key): undefined} >
                        <span className="TableHeaderContent">
                            { column.name }
                            { sortColumn === column.key ? sortDirection === 'asc' ? 
                                <ReactSVG className="TableHeaderContentSVG" src='https://unpkg.com/@alaskaairux/icons@latest/dist/icons/interface/arrow-up.svg'/> : <ReactSVG className="TableHeaderContentSVG" src='https://unpkg.com/@alaskaairux/icons@latest/dist/icons/interface/arrow-down.svg'/> 
                                : undefined }
                        </span>
                    </td>                                                                
                ))
            }
            {
                <td key="deleteAction" className="TableHeader">{""}</td>
            }          
            </tr>
        </thead>
        <tbody>
          {
            tableData.length ===0 ? <tr><td colSpan={columnsDefinition.length + 1}>No data found or an error occurred while retrieving data.            </td></tr> :            
            tableData.map((row, index) => (
              <tr key={index}>
                {                    
                    columnsDefinition.map((column) => (
                        column.dataFormat === 'date' 
                        ? <td key={column.key.concat(index.toString())}>
                            {
                                new Intl.DateTimeFormat("en-US", {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    second: "2-digit",
                                    hour12: true,
                                  }).format(new Date(row[column.key]))                                
                            }</td> 
                        : <td key={column.key.concat(index.toString())}>{row[column.key]}</td>
                    ))                    
                }
                {                    
                    <td>
                        <auro-button tertiary slim iconOnly role="button" aria-label="deleteTableRow" onClick={() => onDeleteClick(row[keyFieldName])}>
                            <ReactSVG src='https://unpkg.com/@alaskaairux/icons@latest/dist/icons/interface/x-stroke.svg'/>
                        </auro-button>
                    </td>  
                }                               
              </tr>))
          }                                           
      </tbody>
  </table>)
};

